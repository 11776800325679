import React from "react"
import styled from '@emotion/styled'

import Apples from './apples'
import Beets from './beets'
import Cabbage from './cabbage'
import Carrots from './carrots'
import Cauliflower from './cauliflower'
import Cilantro from './cilantro'
import Collards from './collards'
import Corn from './corn'
import Endive from './endive'
import Grapes from './grapes'
import Kale from './kale'
import Lettuce from './lettuce'
import Leeks from './leeks'
import Mustard from './mustard'
import DryOnions from './dryonions'
import GreenOnions from './greenonions'
import Parsley from './parsley'
import Parsnips from './parsnips'
import BellPeppers from './bellpeppers'
import HotPeppers from './hotpeppers'
import SweetPeppers from './sweetpeppers'
import Potatoes from './potatoes'
import Pumpkins from './pumpkins'
import Radishes from './radishes'
import Spinach from './spinach'
import Squash from './squash'
import Tomato from './tomatoes'
import TurnipGreens from './turnipgreens'
import Turnips from './turnips'
import Watermelon from './watermelon'

const HeroImage = () => {

  const Wrapper = styled.div`
    width: 100%;
    background: #f9f9f9;
  `

  const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2em 1.5em;
    color: #545454;
    height: 100%;

  `

  const Title = styled.h3`
    font-size: 1.5em;
    color: #F32B38;
    font-family: "Roboto Slab";
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    line-height: 115%;
    margin-bottom: .75em;
    text-align: left;

    @media (min-width: 1024px) {
      font-size: 2em;
      margin-bottom: .75em;
    }
  `

  const Left = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    a {
      &:hover {
        color: #0C4E3F;
      }
    }

    @media (min-width: 1024px) {
      padding-right: 4em;
    }
  `

  const Right = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
  `

  const ProduceName = styled.h5`
    font-family: "Roboto Slab", sans-serif;
    font-size: 1.25em;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
  `
  const ProduceCity = styled.p`
    font-family: "Roboto Slab", sans-serif;
    font-size: 1em;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
  `

  const ProduceDesc = styled.div`
    padding: 1em;
  `

  const Produce = styled.div`
    width: 100%;
    /* padding: 1.5em; */
    margin-bottom: 1em;
    border: 1px solid #dedede;
    background: #fff;

    @media (min-width: 1024px) {
      width: calc(50% - 2em);
      margin: 1em;
      /* padding: 2em; */
    }

    @media (min-width: 1200px) {
      width: calc(33.333% - 2em);
    }
  `

  const ImageWrapper = styled.div`
    width: 100%;
    height: 220px;
  `

  return (
        <Wrapper id="produce">
          <InnerWrapper>
            <Left>
              <Title>October Produce</Title>
            </Left>
            <Right>
              <Produce>
                <ImageWrapper>
                  <Apples />
                </ImageWrapper>
                <ProduceDesc>
                  <ProduceName>
                    Apples
                  </ProduceName>
                  <ProduceCity>
                    Late-season (Red)
                  </ProduceCity>
                </ProduceDesc>
              </Produce>
              <Produce>
                <ImageWrapper>
                  <Beets />
                </ImageWrapper>
                <ProduceDesc>
                  <ProduceName>
                    Beets
                  </ProduceName>
                  <ProduceCity>
                  </ProduceCity>
                </ProduceDesc>
              </Produce>
              <Produce>
                <ImageWrapper>
                  <Cabbage />
                </ImageWrapper>
                <ProduceDesc>
                  <ProduceName>
                    Cabbage
                  </ProduceName>
                  <ProduceCity>
                  </ProduceCity>
                </ProduceDesc>
              </Produce>
              <Produce>
                <ImageWrapper>
                  <Carrots />
                </ImageWrapper>
                <ProduceDesc>
                  <ProduceName>
                    Carrots
                  </ProduceName>
                  <ProduceCity>
                  </ProduceCity>
                </ProduceDesc>
              </Produce>
              <Produce>
                <ImageWrapper>
                  <Cauliflower />
                </ImageWrapper>
                <ProduceDesc>
                  <ProduceName>
                    Cauliflower
                  </ProduceName>
                  <ProduceCity>
                  </ProduceCity>
                </ProduceDesc>
              </Produce>
              <Produce>
                <ImageWrapper>
                  <Cilantro />
                </ImageWrapper>
                <ProduceDesc>
                  <ProduceName>
                    Cilantro
                  </ProduceName>
                  <ProduceCity>
                  </ProduceCity>
                </ProduceDesc>
              </Produce>
              <Produce>
                <ImageWrapper>
                  <Collards />
                </ImageWrapper>
                <ProduceDesc>
                  <ProduceName>
                    Collard Greens
                  </ProduceName>
                  <ProduceCity>
                  </ProduceCity>
                </ProduceDesc>
              </Produce>
              <Produce>
                <ImageWrapper>
                  <Corn />
                </ImageWrapper>
                <ProduceDesc>
                  <ProduceName>
                    Corn
                  </ProduceName>
                  <ProduceCity>
                    Sweet
                  </ProduceCity>
                </ProduceDesc>
              </Produce>
              <Produce>
                <ImageWrapper>
                  <Endive />
                </ImageWrapper>
                <ProduceDesc>
                  <ProduceName>
                    Endive & Escarole
                  </ProduceName>
                  <ProduceCity>
                  </ProduceCity>
                </ProduceDesc>
              </Produce>
              <Produce>
                <ImageWrapper>
                  <Grapes />
                </ImageWrapper>
                <ProduceDesc>
                  <ProduceName>
                    Grapes
                  </ProduceName>
                  <ProduceCity>
                    Table
                  </ProduceCity>
                </ProduceDesc>
              </Produce>
              <Produce>
                <ImageWrapper>
                  <Kale />
                </ImageWrapper>
                <ProduceDesc>
                  <ProduceName>
                    Kale
                  </ProduceName>
                  <ProduceCity>
                  </ProduceCity>
                </ProduceDesc>
              </Produce>
              <Produce>
                <ImageWrapper>
                  <Lettuce />
                </ImageWrapper>
                <ProduceDesc>
                  <ProduceName>
                    Lettuce
                  </ProduceName>
                  <ProduceCity>
                    Leafy
                  </ProduceCity>
                </ProduceDesc>
              </Produce>
              <Produce>
                <ImageWrapper>
                  <Leeks />
                </ImageWrapper>
                <ProduceDesc>
                  <ProduceName>
                    Leeks
                  </ProduceName>
                  <ProduceCity>
                  </ProduceCity>
                </ProduceDesc>
              </Produce>
              <Produce>
                <ImageWrapper>
                  <Mustard />
                </ImageWrapper>
                <ProduceDesc>
                  <ProduceName>
                    Mustard Greens
                  </ProduceName>
                  <ProduceCity>
                  </ProduceCity>
                </ProduceDesc>
              </Produce>
              <Produce>
                <ImageWrapper>
                  <DryOnions />
                </ImageWrapper>
                <ProduceDesc>
                  <ProduceName>
                    Onions
                  </ProduceName>
                  <ProduceCity>
                  Dry
                  </ProduceCity>
                </ProduceDesc>
              </Produce>
              <Produce>
                <ImageWrapper>
                  <GreenOnions />
                </ImageWrapper>
                <ProduceDesc>
                  <ProduceName>
                    Onions
                  </ProduceName>
                  <ProduceCity>
                    Green
                  </ProduceCity>
                </ProduceDesc>
              </Produce>
              <Produce>
                <ImageWrapper>
                  <Parsley />
                </ImageWrapper>
                <ProduceDesc>
                  <ProduceName>
                    Parsley
                  </ProduceName>
                  <ProduceCity>
                  </ProduceCity>
                </ProduceDesc>
              </Produce>
              <Produce>
                <ImageWrapper>
                  <Parsnips />
                </ImageWrapper>
                <ProduceDesc>
                  <ProduceName>
                    Parsnips
                  </ProduceName>
                  <ProduceCity>
                  </ProduceCity>
                </ProduceDesc>
              </Produce>
              <Produce>
                <ImageWrapper>
                  <BellPeppers />
                </ImageWrapper>
                <ProduceDesc>
                  <ProduceName>
                    Peppers
                  </ProduceName>
                  <ProduceCity>
                    Bell
                  </ProduceCity>
                </ProduceDesc>
              </Produce>
              <Produce>
                <ImageWrapper>
                  <HotPeppers />
                </ImageWrapper>
                <ProduceDesc>
                  <ProduceName>
                    Peppers
                  </ProduceName>
                  <ProduceCity>
                    Hot
                  </ProduceCity>
                </ProduceDesc>
              </Produce>
              <Produce>
                <ImageWrapper>
                  <SweetPeppers />
                </ImageWrapper>
                <ProduceDesc>
                  <ProduceName>
                    Peppers
                  </ProduceName>
                  <ProduceCity>
                    Sweet
                  </ProduceCity>
                </ProduceDesc>
              </Produce>
              <Produce>
                <ImageWrapper>
                  <Potatoes />
                </ImageWrapper>
                <ProduceDesc>
                  <ProduceName>
                    Potatoes
                  </ProduceName>
                  <ProduceCity>
                  </ProduceCity>
                </ProduceDesc>
              </Produce>
              <Produce>
                <ImageWrapper>
                  <Pumpkins />
                </ImageWrapper>
                <ProduceDesc>
                  <ProduceName>
                    Pumpkins
                  </ProduceName>
                  <ProduceCity>
                  </ProduceCity>
                </ProduceDesc>
              </Produce>
              <Produce>
                <ImageWrapper>
                  <Radishes />
                </ImageWrapper>
                <ProduceDesc>
                  <ProduceName>
                    Radishes
                  </ProduceName>
                  <ProduceCity>
                    Radishes
                  </ProduceCity>
                </ProduceDesc>
              </Produce>
              <Produce>
                <ImageWrapper>
                  <Spinach />
                </ImageWrapper>
                <ProduceDesc>
                  <ProduceName>
                    Spinach
                  </ProduceName>
                  <ProduceCity>
                  </ProduceCity>
                </ProduceDesc>
              </Produce>
              <Produce>
                <ImageWrapper>
                  <Squash />
                </ImageWrapper>
                <ProduceDesc>
                  <ProduceName>
                    Squash
                  </ProduceName>
                  <ProduceCity>
                    Winter
                  </ProduceCity>
                </ProduceDesc>
              </Produce>
              <Produce>
                <ImageWrapper>
                  <Tomato />
                </ImageWrapper>
                <ProduceDesc>
                  <ProduceName>
                    Tomatoes
                  </ProduceName>
                  <ProduceCity>
                  </ProduceCity>
                </ProduceDesc>
              </Produce>
              <Produce>
                <ImageWrapper>
                  <TurnipGreens />
                </ImageWrapper>
                <ProduceDesc>
                  <ProduceName>
                    Turnip Greens
                  </ProduceName>
                  <ProduceCity>
                  </ProduceCity>
                </ProduceDesc>
              </Produce>
              <Produce>
                <ImageWrapper>
                  <Turnips />
                </ImageWrapper>
                <ProduceDesc>
                  <ProduceName>
                    Turnips
                  </ProduceName>
                  <ProduceCity>
                  </ProduceCity>
                </ProduceDesc>
              </Produce>
              <Produce>
                <ImageWrapper>
                  <Watermelon />
                </ImageWrapper>
                <ProduceDesc>
                  <ProduceName>
                    Watermelon
                  </ProduceName>
                  <ProduceCity>
                  </ProduceCity>
                </ProduceDesc>
              </Produce>
            </Right>
          </InnerWrapper>
        </Wrapper>
  )
}

export default HeroImage
