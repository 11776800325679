import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/whatsfresh/hero"
import Intro from "../components/whatsfresh/intro"
import October from "../components/whatsfresh/october"


const Visit = () => (
  <Layout>
    <SEO title="What's Fresh in Fairview" />
    <Hero />
    <Intro />
    <October />
  </Layout>
)

export default Visit
