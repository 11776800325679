import React from "react"
import styled from '@emotion/styled'
import Image from "./image.js"

const HeroImage = () => {

  const Wrapper = styled.div`
    width: 100%;
    border-top: 4px solid #540B0E;
    background: #f9f9f9;
  `

  const InnerWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 4em 1.5em;
    color: #545454;
    display: flex;
    flex-direction: row;
  `

  const Title = styled.h3`
    font-size: 2em;
    color: #F32B38;
    font-family: "Roboto Slab";
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    line-height: 115%;
    margin-bottom: .35em;

    @media (min-width: 1024px) {
      font-size: 2.5em;
      margin-bottom: .75em;
    }
  `

  const Subtitle = styled.div`
    font-family: "Roboto Slab";
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    font-size: 1.15em;
    margin-bottom: 1.2em;
  `

  const Left = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;

    p {
      font-family: "Roboto", sans-serif;
      font-size: 1.12em;
      margin-bottom: 1.25em;
      font-weight: 400;
      -webkit-font-smoothing: antialiased;
    }

    ul {
      display: flex;
      flex-direction: column;
      margin-top: .5em;
      list-style-type: circle;
      margin-bottom: 4em;

      li {
        margin-left: 1em;
        font-family: "Roboto", sans-serif;
        font-size: 1.12em;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        }
      }
    }

    @media (min-width: 1024px) {
      padding-right: 4em;
    }
  `

  const Right = styled.div`
    display: none;

    @media (min-width: 1024px) {
      display: block;
      margin-top: 2em;
    }
  `

  const ImageWrapper = styled.div`
    width: 425px;
    height: auto;
  `

  return (
        <Wrapper>
          <InnerWrapper>
            <Left>
              <Title>What's fresh in October?</Title>
              <Subtitle>Fruits and vegetables typically grown in Ohio in October are listed below. Their availability varies according to growing conditions and time of the year. Many fruits and vegetables are available beyond the indicated harvest periods through modern storage techniques and facilities.</Subtitle>
              <p>October is the harvest season, and we're happy it's fall. What does autumn look like where you're at right now? For us it looks like apple picking and the farmers market full of squash, green tomatoes, and late season fruit.</p>
              <p>We’ve said goodbye to 90+ degree days and a few rains have fallen. It was a very dry and warm summer, but now it’s time to get ready for indoor activities and start preparing for winter.</p>
            </Left>
            <Right>
              <ImageWrapper>
                <Image />
              </ImageWrapper>
            </Right>
          </InnerWrapper>
        </Wrapper>
  )
}

export default HeroImage
