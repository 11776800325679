import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'


const FreshWrapper = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "tomato.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const backgroundFluidImageStack = [
    data.placeholderImage.childImageSharp.fluid,
    `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))`,
  ].reverse()


  return (

  <BackgroundImage
    fluid={backgroundFluidImageStack}
    style={{width: "auto", height: "100%", backgroundSize: "contain", backgroundPosition: "center"}}
    >
  </BackgroundImage>
  )
}

export default FreshWrapper
